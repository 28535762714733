import React from "react";
import { TracksProps } from "../INTERFACE AND PROPS/RoundupProps";
import Track from "../Components/Track";

const TopTracks = ({ tracks }: TracksProps) => {

    return (
        <div className="column is-one-third rp-top-tracks rp-section is-flex is-flex-direction-column rp-border-right">
            <div className="rp-tracks-items is-flex is-flex-direction-column is-justify-content-space-between">
                {
                    tracks.map(track => {
                        return <Track track={track} />
                    })
                }

            </div>

            <div className="rp-top-track-title">
                <h3 className="rp-heavy-large">top songs</h3>

            </div>

        </div>
    )
}

export default TopTracks