import { RP_ADD_PLAYER, RP_DISCONNECT_PLAYER} from "../../STRINGS/Strings"

export const addPlayer = async (dispatch: any, player: Spotify.Player) => {
    
    dispatch({
        type: RP_ADD_PLAYER,
        player: player
    })
}

export const disconnectPlayer = async (dispatch: any) => {
    
    dispatch({
        type: RP_DISCONNECT_PLAYER,
    })
}

