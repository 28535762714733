import React, { useState } from "react";
import { Outlet } from "react-router-dom";
import "./Style/css/style.css";
import Header from "./Sections/Header";
import Footer from "./Sections/Footer";

const RoundupBlank = () => {
  // VARIABLES
  const [mousex, setMouseX] = useState<number>();
  const [mousey, setMouseY] = useState<number>();

  // FUNCTIONS
  window.addEventListener("mousemove", (e) => {
    e.preventDefault();
    setMouseX(e.pageX);
    setMouseY(e.pageY);
  });

  return (
    <div className="container is-fluid is-flex is-flex-direction-column rp-main-container rp-no-bg p-0 m-0 is-relative">
      <Header showControls={false} />
      <div className="columns rp-view is-flex is-justify-content-center">
        <div className="is-flex is-flex-direction-column is-align-items-center">
          <div
            className={`rp-cursor is-flex is-align-items-center is-justify-content-center`}
            style={{
              left: `${mousex}px`,
              top: `${mousey}px`,
              width: "25px",
              height: "25px",
              borderRadius: "50%",
            }}></div>
          <div>
            <Outlet />
          </div>
        </div>
      </div>
      <Footer />
    </div>
  );
};

export default RoundupBlank;
