import React, { useEffect, useState } from "react";
import { useScriptLoader } from "../../BACKEND/SERVICES/ScriptLoading";
import {
    WEB_PLAYBACK_URL,
    RP_PLAYBACK_ERROR,
    RP_INIT_ERROR,
    RP_AUTH_ERROR,
    RP_ACCOUNT_ERROR,
    RP_PLAYER_NAME,
    RP_PLAY_BUTTON_NAME,
    RP_PLAYER_READY,
    RP_PLAYER_NOT_READY,
    RP_PLAYER_READY_MSG,
    RP_PLAYER_NOT_READY_MSG,
    RP_PLAYER_STATE_CHANGED,
    RP_PLAYER_AUTOPLAY_FAILED,
    RP_PLAYER_AUTOPLAY_FAILED_MSG
} from "../../STRINGS/Strings";
import CurrentlyPlayingTrack from "../Components/CurrentlyPlayingTrack";
import { getSpotifyTrackAsTrackProp } from "../../BACKEND/SpotifyHelperFunctions";
import {
    checkIfAccessTokenExpired,
    getAccessInformationFromLocalStorage,
    refreshAccessToken,
    updateAccessTokensInLocalStorage
} from "../../BACKEND/SERVICES/SpotifyAuthorizationServices";
import { useDispatch } from "react-redux";
import { addPlayer } from "../REDUX AND STATE/PlayerActions";

const CurrentlyPlaying = () => {

    // VARIABLES
    const loaded = useScriptLoader(WEB_PLAYBACK_URL)
    const [player, setPlayer] = useState<Spotify.Player | any>(undefined)
    const [active, setActive] = useState<boolean>(false)
    const [paused, setPaused] = useState<boolean>(true)
    const [track, setTrack] = useState<Spotify.Track | any>(undefined)
    const rectWidth = "1.5"
    const dispatch = useDispatch()


    // HOOKS
    useEffect(() => {
        const accessInfo = getAccessInformationFromLocalStorage()

        // ACTIVATE ELEMENT
        const playButton = document.getElementById(RP_PLAY_BUTTON_NAME)

        window.onSpotifyWebPlaybackSDKReady = () => {
            const player = new window.Spotify.Player({
                name: RP_PLAYER_NAME,
                getOAuthToken: cb => {

                    const isExpired = checkIfAccessTokenExpired(
                        Number(accessInfo.RP_LOCAL_STORAGE_EXPIRE_TIME),
                        Number(accessInfo.RP_LOCAL_STORAGE_TIME))

                    if (isExpired &&
                        accessInfo.RP_LOCAL_STORAGE_REFRESH_TOKEN &&
                        accessInfo.RP_LOCAL_STORAGE_REFRESH_TOKEN !== "null") {
                        refreshAccessToken(accessInfo.RP_LOCAL_STORAGE_REFRESH_TOKEN).then(response => {
                            const { access_token } = response.data
                            updateAccessTokensInLocalStorage(access_token)

                        }).catch(error => {
                            console.log(error)
                        })
                    }

                    cb(accessInfo.RP_LOCAL_STORAGE_ACCESS_TOKEN as string)
                },
                volume: 0.25
            })

            setPlayer(player)

            // ERROR HANDLING
            player.on(RP_PLAYBACK_ERROR, error => {
                console.error("Failed to perform playback because of error: [", RP_PLAYBACK_ERROR.toUpperCase(),
                    "] with message:", error.message);
            });
            player.on(RP_INIT_ERROR, error => {
                console.error("Failed to perform playback because of error: [", RP_INIT_ERROR.toUpperCase(),
                    "] with message:", error.message);
            });
            player.on(RP_AUTH_ERROR, error => {
                console.error("Failed to perform playback because of error: [", RP_AUTH_ERROR.toUpperCase(),
                    "] with message:", error.message);
            });
            player.on(RP_ACCOUNT_ERROR, error => {
                console.error("Failed to perform playback because of error: [", RP_ACCOUNT_ERROR.toUpperCase(),
                    "] with message:", error.message);
            });

            // EVENTS
            player.on(RP_PLAYER_READY, ({ device_id }) => {
                console.log(RP_PLAYER_READY_MSG, device_id)
                player.activateElement()
            })

            player.addListener(RP_PLAYER_STATE_CHANGED, (state) => {
                console.log("STATE CHANGED!")
                if (!state) {
                    return
                }
                setPlayer(player)
                setTrack(state.track_window.current_track)

                player.getCurrentState().then(state => {
                    if (state) {
                        setPaused(state.paused)
                        setActive(!state.paused)
                    }
                })
            })

            player.addListener(RP_PLAYER_NOT_READY, ({ device_id }) => {
                console.log(RP_PLAYER_NOT_READY_MSG, device_id)
            })

            player.addListener(RP_PLAYER_AUTOPLAY_FAILED, () => {
                console.log(RP_PLAYER_AUTOPLAY_FAILED_MSG);
            });

            player.getCurrentState().then(state => {
                if (!state) {
                    console.error('User is not playing music through the Web Playback SDK');
                    player.togglePlay().then(res => {
                    }).catch(error => {
                        console.error(error)
                    })
                }
            })

            player.connect().then(response => {
                console.log(RP_PLAYER_NAME + " successfully connected to Spotify!")
                addPlayer(dispatch, player)
            }).catch(error => {
                console.log(RP_PLAYER_NAME + " failed to connect to Spotify with error: ")
                console.error(error)
            })

            player.activateElement();
        }
    }, [])

    return (
        <div className="column rp-currently-playing rp-section is-flex is-flex-direction-column is-align-items-center is-justify-content-center"
            id="rp-dont-listen"
        >
            <div className="rp-currently-playing-title is-flex is-flex-direction-column is-align-content-flex-start">
                {track ?
                    <h3 className="rp-heavy-medium">currently playing</h3>
                    :
                    <>
                        <h3 className="rp-heavy-medium">connect to <br /> <span className="rp-paragraph-text-large">{RP_PLAYER_NAME}</span> <br /> from your device to start playing</h3>
                    </>
                }
            </div>
            <div className="rp-currently-playing-main is-flex is-flex-direction-column is-align-items-center is-justify-content-center">
                <div className="rp-currently-playing-album-section">
                    {track ?
                        <CurrentlyPlayingTrack track={getSpotifyTrackAsTrackProp(track)} isPlaying={active} />
                        :
                        <svg version="1.1" id="rp-loading-svg-normal" x="0px" y="0px" viewBox="0 0 25 20" enableBackground="new 0 0 100 100">
                            <rect x="0" width={rectWidth} height="20" id="rp-loading-svg-rect-a"></rect>
                            <rect x="5" width={rectWidth} height="20" id="rp-loading-svg-rect-b"></rect>
                            <rect x="10" width={rectWidth} height="20" id="rp-loading-svg-rect-c"></rect>
                            <rect x="15" width={rectWidth} height="20" id="rp-loading-svg-rect-d"></rect>
                            <rect x="20" width={rectWidth} height="20" id="rp-loading-svg-rect-e"></rect>
                        </svg>
                    }
                </div>

                <div className="rp-player-controls-background is-flex is-align-items-center is-justify-content-center">
                    <div className="rp-player-controls is-flex is-align-content-center">

                        <button className="rp-prev" onClick={() => {
                            player?.previousTrack()
                        }}>
                            <i className="fa-solid fa-backward-step"></i>
                        </button>
                        <button className="rp-play-pause" id="rp-play-pause" onClick={() => {
                            player?.togglePlay()
                        }}>
                            <span className="fa-stack fa-1x">
                                <i className="fa-solid fa-circle fa-stack-1x rp-green" />
                                <i className={`fa-solid fa-circle-${active ? "pause" : "play"} fa-stack-1x`} />
                            </span>
                        </button>
                        <button className="rp-next" onClick={() => {
                            player?.nextTrack()
                        }}>
                            <i className="fa-solid fa-forward-step"></i>

                        </button>

                    </div>

                </div>

            </div>
        </div>
    )
}

export default CurrentlyPlaying