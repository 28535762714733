import React, { useEffect, useRef, useState } from "react";
import { RecommendationSingleProp } from "../INTERFACE AND PROPS/RoundupProps";
import RoundedIconPlain from "./RoundedIconPlain";

const Recommendation = ({ track, isHover, generateRecommendation }: RecommendationSingleProp) => {
    // VARIABLES
    const [isPlaying, setIsPlaying] = useState(false)
    let audio = new Audio()
    const audioRef = useRef(audio)

    // HOOKS
    useEffect(() => {
        if (track?.preview) {
            const xhr = new XMLHttpRequest()
            xhr.open("GET", track?.preview as string, true)
            xhr.responseType = "blob"
            xhr.onload = () => {
                audioRef.current.src = URL.createObjectURL(xhr.response)
            }
            xhr.send()
        }
    }, [track])

    return (
      <div className="rp-recommendation is-flex is-align-items-center is-justify-content-space-between">
        <div
          onMouseEnter={() => {
            isHover(true, track);
            if (!isPlaying) {
              audioRef.current
                .play()
                .then((response) => {
                  setIsPlaying(true);
                })
                .catch((error) => {
                  console.error(error.message);
                });
            }
          }}
          onMouseLeave={() => {
            isHover(false, undefined);
            setIsPlaying(false);
            audioRef.current.pause();
          }}>
          {track === undefined ? null : (
            <RoundedIconPlain image={track.album.cover} />
          )}
        </div>

        <div className="rp-recommendation-button">
          <button
            className="is-flex is-justify-content-center is-align-items-center"
            onClick={() => generateRecommendation()}>
            <i className="fa-solid fa-arrows-rotate" />
          </button>
        </div>
      </div>
    );

}

export default Recommendation;