import React from "react";
import { GenreProp } from "../INTERFACE AND PROPS/RoundupProps";

const Genre = ({ genre }: GenreProp) => {

    return (
        <div className="rp-top-genre rp-section rp-two-fifths-height rp-border-bottom is-flex is-align-items-center is-justify-content-space-between"
            id="rp-dont-listen"
        >
            <div className="rp-genre">

                <h3 className="rp-heavy-xlarge" id="rp-genre">
                    {genre}
                </h3>
            </div>
            <div className="rp-genre-title is-flex is-flex-direction-column is-align-content-flex-start">
                <h3 className="rp-heavy-large">top</h3>
                <h3 className="rp-heavy-large">genre</h3>
            </div>


        </div>
    )
}

export default Genre