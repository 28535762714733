import {
  RP_ARTIST_SEED,
  RP_GENRE_SEED,
  RP_LOCAL_STORAGE_KEYS,
  RP_RANGE_SEED,
  RP_TRACK_SEED
} from "../STRINGS/Strings"
import { GenreType } from "../FRONTEND/INTERFACE AND PROPS/Interface"

export const getRandom = (list: any[], value: number) => {
  const res = []

  for (let i = 0; i < value; i++) {
    const randomInt = Math.floor(Math.random() * list.length)
    res.push(list[randomInt])

  }
  return res
}

export const getValues = (length: number) => {
  switch (length) {
    case 1:
      return {
        RP_TRACK_VALUE: 2,
        RP_ARTIST_VALUE: 2
      }
    case 2:
      return {
        RP_TRACK_VALUE: 2,
        RP_ARTIST_VALUE: 1
      }
    case 3:
      return {
        RP_TRACK_VALUE: 1,
        RP_ARTIST_VALUE: 1
      }
    case 4:
      return {
        RP_TRACK_VALUE: 0,
        RP_ARTIST_VALUE: 1
      }
    default:
      return {
        RP_TRACK_VALUE: 0,
        RP_ARTIST_VALUE: 0
      }
  }
}

export const returnTopArtistsAsProps = (artists: any[]) => {
  const artistsAsProps = artists.map((artist, position) => {
    let artistProp = {
      id: artist.id,
      name: artist.name,
      genres: artist.genres.map((genre: string) => genre.toUpperCase() as GenreType),
      images: artist.images.map((img: any) => img.url),
      position: position + 1,
      followers: artist.followers.total.toLocaleString("en-US")
    }

    return artistProp

  })
  return artistsAsProps
}

export const returnTopTracksAsProps = (tracks: any[]) => {
  const tracksAsProps = tracks.map(track => {
    let trackProp = {
      id: track.id,
      title: track.name,
      artist: track.artists[0].name,
      featuring: getFeatures(track.artists),
      album: {
        title: track.album.name,
        cover: track.album.images[0].url
      },
      length: track.duration_ms,
      preview: track.preview_url
    }

    return trackProp

  })
  return tracksAsProps
}

export const getFeatures = (artists: any[]) => {
  const mainArtist = artists[0]
  const features = artists.filter(artist => {
    return artist.name !== mainArtist.name
  })

  return features
}

export const getRecommendationAsTrackProp = (recommendation: any) => {
  let trackProp = {
    id: recommendation.id,
    title: recommendation.name,
    artist: recommendation.artists[0].name,
    featuring: getFeatures(recommendation.artists),
    album: {
      title: recommendation.album.name,
      cover: recommendation.album.images[0].url
    },
    length: recommendation.duration_ms,
    preview: recommendation.preview_url
  }

  return trackProp
}

export const getRecommendationSeedInfo = () => {

  return {
    RP_TRACK_SEED: localStorage.getItem(RP_TRACK_SEED),
    RP_ARTIST_SEED: localStorage.getItem(RP_ARTIST_SEED),
    RP_GENRE_SEED: localStorage.getItem(RP_GENRE_SEED),
    RP_RANGE_SEED: localStorage.getItem(RP_RANGE_SEED)
  }

}

export const setSeedInfoToLocalStorage = (artists: string, tracks: string, genres: string, range: string) => {

  localStorage.setItem(RP_ARTIST_SEED, artists)
  localStorage.setItem(RP_TRACK_SEED, tracks)
  localStorage.setItem(RP_GENRE_SEED, genres)
  localStorage.setItem(RP_RANGE_SEED, range)

}

export const clearStorage = () => {
  for (const localStorageKey of RP_LOCAL_STORAGE_KEYS) {
    localStorage.removeItem(localStorageKey)
  }
}

export const clearSeedStorage = () => {

  localStorage.removeItem(RP_ARTIST_SEED)
  localStorage.removeItem(RP_TRACK_SEED)
  localStorage.removeItem(RP_GENRE_SEED)
  localStorage.removeItem(RP_RANGE_SEED)

}



export const getSpotifyTrackAsTrackProp = (track: any) => {
  let trackProp = {
    id: track.id,
    title: track.name,
    artist: track.artists[0].name,
    featuring: getFeatures(track.artists),
    album: {
      title: track.album.name,
      cover: track.album.images[0].url
    },
    length: track.duration_ms,
    preview: track.preview_url
  }
  return trackProp

}