import React from "react";
import { BrowserRouter as Router, Route, Routes } from "react-router-dom";
import { Navigate } from "react-router-dom";
import "./FRONTEND/Style/css/style.css";
import Roundup from "./FRONTEND/Roundup";
import RoundupWelcome from "./FRONTEND/RoundupWelcome";
import ErrorPage from "./FRONTEND/ErrorPage";
import RoundupBlank from "./FRONTEND/RoundupBlank";
import {
  RP_ERROR_ROUTE,
  RP_HOME_ROUTE,
  RP_WELCOME_ROUTE,
  RP_SIX_MONTHS_ROUTE,
  RP_THIS_MONTH_ROUTE,
  RP_ALL_TIME_ROUTE,
  RP_SHORT_TERM_RANGE,
  RP_MEDIUM_TERM_RANGE,
  RP_LONG_TERM_RANGE,
  RP_CONTACT_ROUTE,
  RP_PRIVACY_POLICY_ROUTE,
  RP_SUCCESS_PAGE,
  RP_FAILURE_PAGE,
  RP_404_PAGE,
  RP_SUCCESS_PAGE_MESSAGE,
  RP_404_PAGE_MESSAGE,
  RP_FAILURE_PAGE_TITLE,
  RP_SUCCESS_PAGE_TITLE,
} from "./STRINGS/Strings";
import PrivacyPolicy from "./FRONTEND/PrivacyPolicy";
import Contact from "./FRONTEND/Contact";
import AnyPage from "./FRONTEND/AnyPage";

export const REACT_APP_BASE = process.env.REACT_APP_BASE;

function App() {
  return (
    <Router>
      <div className="container is-fluid m-0 p-0">
        <Routes>
          <Route path="" element={<Navigate to={RP_WELCOME_ROUTE} />} />
          <Route path="" element={<RoundupBlank />}>
            <Route path={RP_WELCOME_ROUTE} element={<RoundupWelcome />} />
            <Route path={RP_ERROR_ROUTE} element={<ErrorPage />} />
            <Route path={RP_PRIVACY_POLICY_ROUTE} element={<PrivacyPolicy />} />
            <Route path={RP_CONTACT_ROUTE} element={<Contact />} />
            <Route
              path={RP_SUCCESS_PAGE}
              element={
                <AnyPage
                  title={RP_SUCCESS_PAGE_TITLE}
                  message={RP_SUCCESS_PAGE_MESSAGE}
                />
              }
            />
            <Route
              path={RP_FAILURE_PAGE}
              element={<AnyPage title={RP_FAILURE_PAGE_TITLE} message={""} />}
            />
            <Route
              path="*"
              element={
                <AnyPage title={RP_404_PAGE} message={RP_404_PAGE_MESSAGE} />
              }
            />
          </Route>
          <Route
            path={RP_HOME_ROUTE}
            element={<Roundup range={RP_SHORT_TERM_RANGE} />}
          />
          <Route
            path={RP_THIS_MONTH_ROUTE}
            element={<Roundup range={RP_SHORT_TERM_RANGE} />}
          />
          <Route
            path={RP_SIX_MONTHS_ROUTE}
            element={<Roundup range={RP_MEDIUM_TERM_RANGE} />}
          />
          <Route
            path={RP_ALL_TIME_ROUTE}
            element={<Roundup range={RP_LONG_TERM_RANGE} />}
          />
        </Routes>
      </div>
    </Router>
  );
}

export default App;
