import React from 'react';
import './Style/css/style.css';
import { PageProp } from './INTERFACE AND PROPS/RoundupProps';
import { RP_404_PAGE, RP_CONTACT_ROUTE } from '../STRINGS/Strings';
import { Link, useLocation } from 'react-router-dom';

const AnyPage = ({ message, title }: PageProp) => {
  const location = useLocation()
  const url = location.pathname.replace("/", "")

  return (
    <div className="rp-content is-flex is-flex-direction-column is-align-items-center">
      <div className="rp-content-text">
        <h3 className="rp-heavy-large" style={{ textAlign: "center" }}>
          {title}
        </h3>
      </div>
      <h4 className="rp-paragraph-text">
        {message}
      </h4>
      <Link to={`/${url === RP_404_PAGE ? "": RP_CONTACT_ROUTE}`}>
        <div className="rp-connect-button is-flex is-align-content-center is-justify-content-center">
          <button>Go back</button>
        </div>
      </Link>
    </div>
  );
}

export default AnyPage;
