import React from "react";
import { Link } from "react-router-dom";
import { MenuProp } from "../INTERFACE AND PROPS/RoundupProps";

const MenuItem = ({ item, link, isActive }: MenuProp) => {

    return (
        <>
            <Link to={`/${link.replace(" ", "-")}`} className="rp-menu-item rp-menu-selected">
                <h4 className={`rp-menu-item-text ${isActive ? "rp-selected" : ""}`} >{item}</h4>
            </Link>
        </>
    );
}

export default MenuItem;
