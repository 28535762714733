import React from 'react';
import './Style/css/style.css';
import { API_BASE, RP_LOGIN_ROUTE } from '../STRINGS/Strings';

const RoundupWelcome = () => {

  return (
    <div className="rp-content is-flex is-flex-direction-column is-align-items-center">
      <div className="rp-content-text">
        <h3 className="rp-heavy-large" style={{ textAlign: "center" }}>
          welcome
        </h3>
      </div>
      <h4 className="rp-paragraph-text">
        Connect your Spotify account <br /> to get started
      </h4>
      <div>
        <i className="fa-brands fa-spotify rp-spotify-logo" />
      </div>
      <a href={`${API_BASE}/${RP_LOGIN_ROUTE}`}>
        <div className="rp-connect-button is-flex is-align-content-center is-justify-content-center">
          <button>Connect your Spotify account</button>
        </div>
      </a>
    </div>
  );
}

export default RoundupWelcome;
