import React from "react";
import { Link } from "react-router-dom";
import {RP_CONTACT_ROUTE, RP_PRIVACY_POLICY_ROUTE } from "../../STRINGS/Strings";

const Footer = () => {
  return (
    <div className="rp-disclaimer rp-border-top is-flex is-justify-content-space-between p-2">
      <div className="is-flex ">
        <h3 className="pr-4">
          @2023 <span className="rp-bold">roundup</span>
        </h3>
        <h4>
          This site is in no way affiliated with Spotify. We do not store any
          user data.
        </h4>
      </div>

      <div className="rp-other-pages-link">
        <Link
          to={`/${RP_PRIVACY_POLICY_ROUTE}`}
          className="rp-other-link rp-plain pr-4 ">
          Privacy Policy
        </Link>
        <Link to={`/${RP_CONTACT_ROUTE}`} className="rp-other-link rp-plain">
          Get in touch
        </Link>
      </div>
    </div>
  );
};

export default Footer;
