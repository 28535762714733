import React from "react";
import { Link, useLocation, useNavigate } from "react-router-dom";
import { RP_HOME_ROUTE, RP_NAME, RP_THIS_MONTH_ROUTE } from "../../STRINGS/Strings";
import { BooleanProp } from "../INTERFACE AND PROPS/RoundupProps";
import { clearStorage } from "../../BACKEND/SpotifyHelperFunctions";
import MenuItem from "../Components/MenuItem"
import { useDispatch } from "react-redux";
import { disconnectPlayer } from "../REDUX AND STATE/PlayerActions";

const menu_items = [
    {
        title: 'this month',
        link: 'month'
    },
    {
        title: 'six months',
        link: 'six-months'
    },
    {
        title: 'since the beginning of time',
        link: 'all'
    },
]

const Header = ({ showControls }: BooleanProp) => {
    // VARIABLES 
    const navigate = useNavigate()
    let { pathname } = useLocation()
    pathname = pathname.replace("/", "")
    const dispatch = useDispatch()

    const isSelected = (link: string) => {
        if (pathname.replace("/", "") === RP_HOME_ROUTE && link === RP_THIS_MONTH_ROUTE) {
            return true
        }
        return pathname === link
    }

    // FUNCTIONS
    const logOut = () => {
        clearStorage()
        disconnectPlayer(dispatch)
        navigate("/")
    }

    return (
      <div
        className={`rp-header rp-border-bottom is-flex is-align-items-center ${
          showControls
            ? "is-justify-content-space-between"
            : "is-justify-content-center"
        }`}>
        <div className="rp-title">
          <Link to={"/"} className="rp-plain">
            <h3 className="rp-title-content rp-display-font">{RP_NAME}</h3>
          </Link>
        </div>
        <div className="rp-divider"></div>
        {showControls ? (
          <div className="rp-menu is-flex is-flex-direction-row is-align-items-center">
            {menu_items.map((item) => {
              return (
                <MenuItem
                  item={item.title}
                  link={item.link}
                  isActive={isSelected(item.link)}
                />
              );
            })}

            <button
              onClick={() => {
                logOut();
              }}>
              <i className="fa-solid fa-person-running" />
            </button>
          </div>
        ) : null}
      </div>
    );
}

export default Header