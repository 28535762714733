import React, { useEffect } from "react";
import "./Style/css/style.css";
import {
  RP_CONTACT_ROUTE,
  RP_EMAIL_LINK,
  RP_NAME,
  RP_URL,
} from "../STRINGS/Strings";
import { clearStorage } from "../BACKEND/SpotifyHelperFunctions";
import { Link } from "react-router-dom";

function PrivacyPolicy() {
  // HOOKS
  useEffect(() => {
    clearStorage();
  });

  return (
    <div className="is-flex is-flex-direction-column rp-privacy-policy pl-6 pr-6">
      <div className="p-6 rp-privacy-content">
        <h3
          className="rp-heavy-large mt-6 mb-6"
          style={{ textAlign: "center" }}>
          Privacy Policy
        </h3>
        <p className="rp-paragraph-text rp-left">
          <strong>Last updated:</strong> January 16, 2023 <br /> <br />
          This Privacy Policy describes Our policies and procedures on the
          collection, use and disclosure of Your information when You use the
          Service and tells You about Your privacy rights and how the law
          protects You. We may use Your Personal data to provide and improve the
          Service. By using the Service, You agree to the collection and use of
          information in accordance with this Privacy Policy.
        </p>
        <div className="rp-paragraph-text mt-6 rp-left">
          <h3 className="rp-heavy-large mt-4">
            Interpretation and Definitions
          </h3>
          <h4 className="rp-heavy-medium mt-4 mb-2">Interpretation</h4>
          <p>
            The words of which the initial letter is capitalized have meanings
            defined under the following conditions. The following definitions
            shall have the same meaning regardless of whether they appear in
            singular or in plural.
          </p>

          <h4 className="rp-heavy-medium mt-4 mb-2">Definitions</h4>
          <p>For the purposes of this Privacy Policy:</p>
          <ul className="mt-2 mb-2">
            <li>
              <strong>Account</strong> means a unique account created for You to
              access our Service or parts of our Service.
            </li>
            <li>
              <strong>Company</strong> (referred to as either &quot;the
              Company&quot;, &quot;We&quot;, &quot;Us&quot; or &quot;Our&quot;
              in this Agreement) refers to {RP_NAME}.
            </li>
            <li>
              <strong>Cookies</strong> are small files that are placed on Your
              computer, mobile device or any other device by a website,
              containing the details of Your browsing history on that website
              among its many uses.
            </li>
            <li>
              <strong>Country</strong> refers to: United States
            </li>
            <li>
              <strong>Device</strong> means any device that can access the
              Service such as a computer, a cellphone or a digital tablet.
            </li>
            <li>
              <strong>Personal Data</strong> is any information that relates to
              an identified or identifiable individual.
            </li>
            <li>
              <strong>Service</strong> refers to the Website.
            </li>
            <li>
              <strong>Service Provider</strong> means any natural or legal
              person who processes the data on behalf of the Company. It refers
              to third-party companies or individuals employed by the Company to
              facilitate the Service, to provide the Service on behalf of the
              Company, to perform services related to the Service or to assist
              the Company in analyzing how the Service is used.
            </li>
            <li>
              <strong>Third-party Social Media Service</strong> refers to any
              website or any social network website through which a User can log
              in or create an account to use the Service.
            </li>
            <li>
              <strong>Usage Data</strong> refers to data collected
              automatically, either generated by the use of the Service or from
              the Service infrastructure itself (for example, the duration of a
              page visit).
            </li>
            <li>
              <strong>Website</strong> refers to {RP_NAME}, accessible from{" "}
              <Link to="/" className="rp-plain-underline rp-bold ">
                <strong>{RP_URL}</strong>
              </Link>
            </li>
            <li>
              <strong>You</strong> means the individual accessing or using the
              Service, or the company, or other legal entity on behalf of which
              such individual is accessing or using the Service, as applicable.
            </li>
          </ul>
        </div>
        <div className="rp-paragraph-text mt-6 rp-left">
          <h3 className="rp-heavy-large mt-4 mb-2">
            Collecting and Using Your Personal Data
          </h3>
          <h4 className="rp-heavy-medium mt-4 mb-2">Types of Data Collected</h4>
          <h4 className="rp-heavy-medium mt-4 mb-2">Personal Data</h4>
          <p>
            While using Our Service, We may ask You to provide Us with certain
            personally identifiable information that can be used to contact or
            identify You. Personally identifiable information may include, but
            is not limited to:
            <ul className="mt-2 mb-2">
              <li>Usage Data</li>
            </ul>
          </p>

          <h4 className="rp-heavy-medium mt-4 mb-2">Usage Data</h4>
          <p>
            Usage Data is collected automatically when using the Service. <br />
            <br />
            Usage Data may include information such as Your Device's Internet
            Protocol address (e.g. IP address), browser type, browser version,
            the pages of our Service that You visit, the time and date of Your
            visit, the time spent on those pages, unique device identifiers and
            other diagnostic data.
            <br />
            <br />
            When You access the Service by or through a mobile device, We may
            collect certain information automatically, including, but not
            limited to, the type of mobile device You use, Your mobile device
            unique ID, the IP address of Your mobile device, Your mobile
            operating system, the type of mobile Internet browser You use,
            unique device identifiers and other diagnostic data.
            <br />
            <br />
            We may also collect information that Your browser sends whenever You
            visit our Service or when You access the Service by or through a
            mobile device.
          </p>
          <h3 className="rp-heavy-medium mt-4 mb-2">
            Information from Third-Party Social Media Services
          </h3>
          <p>
            The Company allows You to create an account and log in to use the
            Service through the following Third-party Social Media Services:
          </p>
          <ul className="mt-2 mb-2">
            <li>Spotify</li>
          </ul>
          <p>
            If You decide to register through or otherwise grant us access to a
            Third-Party Service, We may collect Personal data that is already
            associated with Your Third-Party Social Media Service's account,
            such as Your name, Your email address, Your activities or Your
            contact list associated with that account.
            <br />
            <br />
            You may also have the option of sharing additional information with
            the Company through Your Third-Party Social Media Service's account.
            If You choose to provide such information and Personal Data, during
            registration or otherwise, You are giving the Company permission to
            use, share, and store it in a manner consistent with this Privacy
            Policy.
          </p>
          <h3 className="rp-heavy-medium mt-4 mb-2">
            Tracking Technologies and Cookies
          </h3>
          <p>
            We use Cookies and similar tracking technologies to track the
            activity on Our Service and store certain information. Tracking
            technologies used are beacons, tags, and scripts to collect and
            track information and to improve and analyze Our Service. The
            technologies We use may include:
            <ul className="mt-2 mb-2">
              <li>
                <strong>Cookies or Browser Cookies.</strong> A cookie is a small
                file placed on Your Device. You can instruct Your browser to
                refuse all Cookies or to indicate when a Cookie is being sent.
                However, if You do not accept Cookies, You may not be able to
                use some parts of our Service. Unless you have adjusted Your
                browser setting so that it will refuse Cookies, our Service may
                use Cookies.
              </li>
            </ul>
            Cookies can be &quot;Persistent&quot; or &quot;Session&quot;
            Cookies. Persistent Cookies remain on Your personal computer or
            mobile device when You go offline, while Session Cookies are deleted
            as soon as You close Your web browser.
          </p>
          <p>
            We use both Session and Persistent Cookies for the purposes set out
            below:
          </p>
          <ul className="mt-2 mb-2">
            <li>
              <strong>Necessary / Essential Cookies</strong>
              <br />
              <strong>Type:</strong> Session Cookies
              <br />
              <strong>Administered by:</strong> Us <br />
              <strong>Purpose:</strong> These Cookies are essential to provide
              You with services available through the Website and to enable You
              to use some of its features. They help to authenticate users and
              prevent fraudulent use of user accounts. Without these Cookies,
              the services that You have asked for cannot be provided, and We
              only use these Cookies to provide You with those services.
            </li>
            <li>
              <strong>Cookies Policy / Notice Acceptance Cookies</strong>
              <br />
              <strong>Type:</strong> Persistent Cookies
              <br />
              <strong>Administered by:</strong> Us <br />
              <strong>Purpose:</strong> These Cookies identify if users have
              accepted the use of cookies on the Website.
            </li>
            <li>
              <strong>Functionality Cookies</strong>
              <strong>Purpose:</strong>
              <br />
              <strong>Type:</strong> Persistent Cookies <br />
              <strong>Administered by:</strong> Us <br />
              <strong>Purpose:</strong> These Cookies Purpose: These Cookies
              allow us to remember choices You make when You use the Website,
              such as remembering your login details or language preference. The
              purpose of these Cookies is to provide You with a more personal
              experience and to avoid You having to re-enter your preferences
              every time You use the Website.
            </li>
          </ul>

          <h4 className="rp-heavy-medium mt-4 mb-2">
            Use of Your Personal Data
          </h4>
          <p>The Company may use Personal Data for the following purposes:</p>
          <ul className="mt-2 mb-2">
            <li>
              <strong>To provide and maintain our Service</strong>, including to
              monitor the usage of our Service.
            </li>
            <li>
              <strong>To manage Your Account:</strong> to manage Your
              registration as a user of the Service. The Personal Data You
              provide can give You access to different functionalities of the
              Service that are available to You as a registered user.
            </li>

            <li>
              <strong>To contact You:</strong> To contact You by email,
              telephone calls, SMS, or other equivalent forms of electronic
              communication, such as a mobile application's push notifications
              regarding updates or informative communications related to the
              functionalities, products or contracted services, including the
              security updates, when necessary or reasonable for their
              implementation.
            </li>
            <li>
              <strong>To manage Your requests:</strong> To attend and manage
              Your requests to Us.
            </li>
            <li>
              <strong>For other purposes</strong>: We may use Your information
              for other purposes, such as data analysis, identifying usage
              trends, determining the effectiveness of our promotional campaigns
              and to evaluate and improve our Service, products, services,
              marketing and your experience.
            </li>
          </ul>
          <p>
            We may share Your personal information in the following situations:
          </p>
          <ul className="mt-2 mb-2">
            <li>
              <strong>With Service Providers:</strong> We may share Your
              personal information with Service Providers to monitor and analyze
              the use of our Service, to contact You.
            </li>
            <li>
              <strong>With Your consent</strong>: We may disclose Your personal
              information for any other purpose with Your consent.
            </li>
          </ul>
          <h4 className="rp-heavy-medium mt-4 mb-2">
            Retention of Your Personal Data
          </h4>
          <p>
            The Company will retain Your Personal Data only for as long as is
            necessary for the purposes set out in this Privacy Policy. We will
            retain and use Your Personal Data to the extent necessary to comply
            with our legal obligations (for example, if we are required to
            retain your data to comply with applicable laws), resolve disputes,
            and enforce our legal agreements and policies. <br />
            <br />
            The Company will also retain Usage Data for internal analysis
            purposes. Usage Data is generally retained for a shorter period of
            time, except when this data is used to strengthen the security or to
            improve the functionality of Our Service, or We are legally
            obligated to retain this data for longer time periods.
          </p>
        </div>
        <div className="rp-paragraph-text mt-6 rp-left">
          <h4 className="rp-heavy-large mt-4 mb-2">
            Changes to this Privacy Policy
          </h4>
          <p>
            We may update Our Privacy Policy from time to time. We will notify
            You of any changes by posting the new Privacy Policy on this page.
          </p>
          <p>
            We will let You know via email and/or a prominent notice on Our
            Service, prior to the change becoming effective and update the
            &quot;Last updated&quot; date at the top of this Privacy Policy.
          </p>
          <p>
            You are advised to review this Privacy Policy periodically for any
            changes. Changes to this Privacy Policy are effective when they are
            posted on this page.
          </p>
        </div>

        <div className="rp-paragraph-text mt-6 rp-left">
          <h4 className="rp-heavy-large mt-4 mb-2">Contact Us</h4>
          <p>
            If you have any questions about this Privacy Policy, You can contact
            us:
          </p>
          <ul className="mt-2 mb-2">
            <li>
              By email:{" "}
              <form
                action={RP_EMAIL_LINK}
                method="GET"
                encType="text/plain"
                className="is-inline">
                <label
                  htmlFor="rp_submit_email"
                  className="rp-plain-underline rp-bold">
                  hello@roundup.me
                </label>
                <input
                  type="submit"
                  id="rp_submit_email"
                  value={" "}
                  className="is-hidden"
                />
              </form>
            </li>
            <li>
              By visiting this page on our website:{" "}
              <Link
                to={`/${RP_CONTACT_ROUTE}`}
                className="rp-bold rp-plain-underline">
                {`${RP_URL}/${RP_CONTACT_ROUTE}`}
              </Link>
            </li>
          </ul>
        </div>
      </div>
    </div>
  );
}

export default PrivacyPolicy;
