/**
 * Handles loading object from a script file 
 * https://www.newline.co/fullstack-react/articles/Declaratively_loading_JS_libraries/index.html
 */

import { useEffect, useState } from "react";
import { LOADING, ERROR, IDLE, READY } from "../../STRINGS/Strings";

export type ScriptLoaderStatus = typeof LOADING | typeof IDLE | typeof READY | typeof ERROR
export const useScriptLoader = (url: string) => {

    const [state, setState] = useState<ScriptLoaderStatus>(url ? LOADING : IDLE)

    useEffect(() => {

        if (!url) {
            setState(ERROR)
            return
        }

        let script: HTMLScriptElement | null = document.querySelector(`script[src="${url}]"`)

        const updatetStateFromEvent = (event: Event) => {
            setState(event.type === LOADING ? READY : ERROR)
        }

        if (!script) {
            script = document.createElement("script")
            script.type = "application/javascript"
            script.src = url
            script.async = true
            document.body.appendChild(script)

            script.addEventListener(LOADING as string, updatetStateFromEvent)
            script.addEventListener(ERROR as string, updatetStateFromEvent)

        }

        script.addEventListener(LOADING as string, updatetStateFromEvent)
        script.addEventListener(ERROR as string, updatetStateFromEvent)

        return () => {
            if (script) {
                script.addEventListener(LOADING as string, updatetStateFromEvent)
                script.addEventListener(ERROR as string, updatetStateFromEvent)
            }

        }
        
    }, [url])

    return state
}