import React, { useEffect, useState } from "react";
import { useLocation, useNavigate, useParams } from "react-router-dom";
import "./Style/css/style.css";
import {
  RP_DATA_RETRIEVAL_ERROR,
  RP_ERROR_ROUTE,
  RP_SEED_GENERATION_ERROR,
  RP_SET_ERROR,
} from "../STRINGS/Strings";
import { Artist, GenreType, Track } from "./INTERFACE AND PROPS/Interface";
import { RangeProp } from "./INTERFACE AND PROPS/RoundupProps";
import { getAccessInformationFromSearchParams } from "../BACKEND/SERVICES/SpotifyAuthorizationServices";
import {
  getRecommendations,
  getTopArtists,
  getTopGenre,
  getTopGenres,
  getTopTracks,
} from "../BACKEND/SERVICES/SpotifyAPIServices";
import {
  getRandom,
  getRecommendationSeedInfo,
  getValues,
  setSeedInfoToLocalStorage,
} from "../BACKEND/SpotifyHelperFunctions";
import TopArtist from "./Sections/TopArtists";
import TopTracks from "./Sections/TopTracks";
import Header from "./Sections/Header";
import CurrentlyPlaying from "./Sections/CurrentlyPlaying";
import Recommendations from "./Sections/Recommendations";
import Genre from "./Sections/Genre";
import Footer from "./Sections/Footer";

const Roundup = ({ range }: RangeProp) => {
  // VARIABLES
  const location = useLocation();
  const navigate = useNavigate();
  const { name } = useParams();
  const [accessInfo, setAccessInfo] = useState(Object);
  const [tracks, setTracks] = useState<Track[]>([]);
  const [genre, setGenre] = useState<GenreType>("None");
  const [topGenres, setTopGenres] = useState<string[]>([]);
  const [artists, setArtists] = useState<Artist[]>([]);
  const [recommendations, setRecommendations] = useState<any[]>([]);
  const [token, setToken] = useState<string>("null");

  const [mousex, setMouseX] = useState<number>();
  const [mousey, setMouseY] = useState<number>();
  const [width, setWidth] = useState<number>(25);
  const [height, setHeight] = useState<number>(25);
  const [radius, setRadius] = useState<string>("50%");
  const [album, setAlbum] = useState<string>("");
  const [artist, setArtist] = useState<string>("");

  window.addEventListener("mousemove", (e) => {
    e.preventDefault();
    setMouseX(e.pageX);
    setMouseY(e.pageY);
  });

  // HOOKS
  useEffect(() => {
    async function setUp() {
      const search = location.search;
      const params = new URLSearchParams(search);
      const storedAccessInfo = getAccessInformationFromSearchParams(params);
      setAccessInfo(storedAccessInfo);
      setToken(accessInfo.RP_LOCAL_STORAGE_ACCESS_TOKEN);
    }

    async function getUserData() {
      getTopArtists(range)
        .then((response) => {
          setArtists(response);
        })
        .catch((error) => {
          console.log(RP_DATA_RETRIEVAL_ERROR.toUpperCase());
          window.location.reload();
        });

      getTopTracks(range)
        .then((response) => {
          setTracks(response);
        })
        .catch((error) => {
          console.log(RP_DATA_RETRIEVAL_ERROR.toUpperCase());
          window.location.reload();
        });
    }

    async function getGenreAndSeedInfo() {
      getTopGenre(range).then((response) => {
        setGenre(response.RP_TOP_GENRE);
        getTopGenres(response.RP_GENRES).then((res) => {
          setTopGenres(res);
          // Check if recommendation seeds have been saved to localstorage
          const seedInfo = getRecommendationSeedInfo();

          if (
            seedInfo &&
            seedInfo.RP_ARTIST_SEED &&
            seedInfo.RP_ARTIST_SEED !== "null" &&
            seedInfo.RP_RANGE_SEED === range
          ) {
            const artistSeed = seedInfo.RP_ARTIST_SEED;
            const trackSeed = seedInfo.RP_TRACK_SEED as string;
            const genreSeed = seedInfo.RP_GENRE_SEED as string;

            getRecommendations(artistSeed, trackSeed, genreSeed)
              .then((res) => {
                setRecommendations(res);
              })
              .catch((error) => {
                console.log(error);
              });
          } else {
            const values = getValues(topGenres.length);

            const recommendationArtists = getRandom(
              artists,
              values.RP_ARTIST_VALUE
            )
              .map((artist) => {
                return artist.id;
              })
              .join(",");

            const recommendationTracks = getRandom(
              tracks,
              values.RP_TRACK_VALUE
            )
              .map((track) => {
                return track.id;
              })
              .join(",");

            setSeedInfoToLocalStorage(
              recommendationArtists,
              recommendationTracks,
              topGenres.join(","),
              range
            );
          }
        });
      });
    }

    setUp()
      .then(() => {
        getUserData()
          .then((res) => {
            getGenreAndSeedInfo()
              .then(() => {
                console.log("REC SEED UPDATED!");
              })
              .catch((error) => {
                console.log(RP_SEED_GENERATION_ERROR.toUpperCase());
                console.error(error);
              });
          })
          .catch((error) => {
            console.log(RP_DATA_RETRIEVAL_ERROR.toUpperCase());
            console.error(error);
          });
      })
      .catch((error) => {
        console.log(RP_SET_ERROR.toUpperCase());
        console.error(error);
      });
  }, [name, range, token]);

  // FUNCTIONS
  const isHovering = (
    isHover: boolean,
    currentRecommendation: Track | undefined
  ) => {
    if (currentRecommendation == null) {
      setAlbum("");
      setArtist("");
      setWidth(25);
      setHeight(25);
      setRadius("50%");
    } else {
      setAlbum(currentRecommendation.title);
      setArtist(currentRecommendation.artist);
      setWidth(265);
      setHeight(125);
      setRadius("60px");
    }
  };

  const updateCursor = (newRecommendation: Track | undefined) => {
    if (newRecommendation !== undefined) {
      setAlbum(newRecommendation.title);
      setArtist(newRecommendation.artist);
    }
  };

  // CONDITIONS
  if (token === "null") {
    navigate(`/${RP_ERROR_ROUTE}`);
  }

  return (
    <div className="container is-fluid is-flex is-flex-direction-column rp-main-container p-0 m-0 is-relative">
      <Header showControls={true} />
      <div
        className={`rp-cursor is-flex is-align-items-center is-justify-content-center rp-darken`}
        style={{
          left: `${mousex}px`,
          top: `${mousey}px`,
          width: `${width}px`,
          height: `${height}px`,
          borderRadius: `${radius}`,
        }}>
        <div className="is-flex is-flex-direction-column is-align-content-center rp-cursor-content">
          <h3
            className="rp-heavy-small"
            style={{
              fontSize: "13px",
              textAlign: "center",
              color: "#F8F7F9",
              marginBottom: "10px",
            }}>
            {album}
          </h3>
          <h3
            className="rp-heavy-small"
            style={{
              fontSize: "12px",
              textAlign: "center",
              color: "#F8F7F9",
            }}>
            {artist}
          </h3>
        </div>
      </div>
      <div className="columns is-gapless rp-view mb-0">
        <TopTracks tracks={tracks} />
        <div className="column is-flex is-flex-direction-column rp-section">
          <TopArtist artists={artists} />
          <div className="columns is-gapless rp-half-height rp-section is-flex">
            <div className="column is-half rp-section rp-border-right is-flex is-flex-direction-column is-align-items-stretch">
              {genre === "None" ? null : <Genre genre={genre} />}

              <Recommendations
                recommendations={recommendations}
                isHovering={isHovering}
                updateCursor={updateCursor}
              />
            </div>
            {token === "null" ? null : <CurrentlyPlaying />}
          </div>
        </div>
      </div>
      <Footer/>
    </div>
  );
};

export default Roundup;
