import React from "react";
import { ArtistInfoProp } from "../INTERFACE AND PROPS/RoundupProps";

const RoundIconOverlay = ({ name, image, isHover }: ArtistInfoProp) => {
    
    return (
      <div
        className="rp-round-icon-overlay"
        onMouseEnter={(event) => isHover(true)}
        onMouseLeave={(event) => isHover(false)}>
        <img
          src={`${image === undefined ? "" : image}`}
          alt=""
          className="rp-round-icon-img"
        />
        <div className="rp-colored-bg">
          <h3 className="rp-image-overlay rp-heavy-xsmall">{name} </h3>
        </div>
      </div>
    );

}

export default RoundIconOverlay