import React, { useState } from "react";
import { ArtistsProps } from "../INTERFACE AND PROPS/RoundupProps";
import { Artist } from "../INTERFACE AND PROPS/Interface";
import Artists from "../Components/Artists";

const TopArtist = ({ artists }: ArtistsProps) => {
    // VARIABLES
    const [hover, setHover] = useState<boolean>(false)
    const [selectedArtist, setSelectedArtist] = useState<Artist | null>(null)

    // FUNCTIONS
    const isHover = (isHover: boolean, artist: Artist | null) => {
        setHover(isHover)
        setSelectedArtist(artist)

    }

    return (
      <div className="rp-top-artists rp-half-height rp-section rp-border-bottom is-flex is-align-items-center">
        <div
          className="rp-top-artist-info"
          style={{
            backgroundImage: `url(${
              selectedArtist ? selectedArtist?.images[0] : ""
            })`,
          }}>
          {selectedArtist === null ? null : (
            <>
              <div className="rp-followers is-flex is-flex-direction-column is-align-items-center is-justify-content-center">
                <h3 className="rp-followers-font rp-bold">FOLLOWERS</h3>
                <h3 className="rp-followers-font rp-bold">
                  {selectedArtist?.followers}
                </h3>
              </div>
              <div className="rp-rank rp-bold is-flex is-flex-direction-column is-align-items-center is-justify-content-center rp-heavy-xlarge ">
                <h3 className="rp-bold">{selectedArtist?.position}</h3>
              </div>
            </>
          )}
        </div>
        <div className="rp-artist-items is-flex is-align-items-center is-justify-content-space-between">
          {artists.map((artist) => {
            return (
              <Artists
                artist={artist}
                isHover={isHover}
                hide={selectedArtist === artist || selectedArtist === null}
              />
            );
          })}
        </div>

        <div className="rp-top-artist-title-left is-flex is-justify-content-center is-align-items-center">
          <h3 className="rp-heavy-large">top</h3>
        </div>

        <div className="rp-top-artist-title-right is-flex is-justify-content-center is-align-items-center">
          <h3 className="rp-heavy-large">artists</h3>
        </div>
      </div>
    );
}

export default TopArtist