import React, { useEffect } from 'react';
import './Style/css/style.css';
import { API_BASE, RP_LOGIN_ROUTE } from '../STRINGS/Strings';
import { clearStorage } from '../BACKEND/SpotifyHelperFunctions';

function ErrorPage() {
  
  // HOOKS
  useEffect(() => {
    clearStorage()
  })

  return (
    <div className="rp-content is-flex is-flex-direction-column is-align-items-center">
      <div className="rp-welcome-text">
        <h3 className="rp-heavy-large" style={{ textAlign: "center" }}>
          oh no!
        </h3>
      </div>
      <h4 className="rp-paragraph-text">
        Something went wrong. <br /> Try again?
      </h4>
      <a href={`${API_BASE}/${RP_LOGIN_ROUTE}`}>
        <div className="rp-connect-button is-flex is-align-content-center is-justify-content-center">
          <button>Connect your Spotify account again</button>
        </div>
      </a>
    </div>
  );
}

export default ErrorPage;
