import React, { useEffect, useState } from "react";
import { CurrentlyPlayingProps } from "../INTERFACE AND PROPS/RoundupProps";
import RoundedIcon from "./RoundedIcon";

const CurrentlyPlayingTrack = ({ track, isPlaying }: CurrentlyPlayingProps) => {
    // VARIABLES
    const [animated, setAnimated] = useState("")
    const rectWidth = "1.5"

    // HOOKS
    useEffect(() => {
        setAnimated(isPlaying ? "-animated" : "")
    }, [isPlaying])

    // FUNCTIONS
    const isHover = (isHover: boolean) => { }

    return (
        <div className="rp-currently-playing-track is-flex is-flex-direction-column is-justify-items-center is-align-items-center">
            <RoundedIcon image={track.album.cover} shrink={false} isHover={isHover} />

            <div className="rp-currently-playing-info is-flex is-flex-direction-column is-justify-items-center is-align-items-center">
                <h2 className="rp-light-small">{track.title}</h2>
                <h2 className="rp-heavy-small">{track.artist}</h2>

            </div>

            <svg version="1.1" id={`rp-loading-svg${animated}`} x="0px" y="0px" viewBox="0 0 25 20" enableBackground="new 0 0 100 100">
                <rect x="0" width={rectWidth} height="20" id={`rp-loading-svg${animated}-rect-a`}></rect>
                <rect x="5" width={rectWidth} height="20" id={`rp-loading-svg${animated}-rect-b`}></rect>
                <rect x="10" width={rectWidth} height="20" id={`rp-loading-svg${animated}-rect-c`}></rect>
                <rect x="15" width={rectWidth} height="20" id={`rp-loading-svg${animated}-rect-d`}></rect>
                <rect x="20" width={rectWidth} height="20" id={`rp-loading-svg${animated}-rect-e`}></rect>
            </svg>

        </div>
    )
}

export default CurrentlyPlayingTrack