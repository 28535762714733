import axios from 'axios'
import {
    RP_LOCAL_STORAGE_ACCESS_TOKEN,
    RP_TOP_ARTISTS_URL,
    RP_TOP_TRACKS_URL,
    RP_RECOMMENDATIONS_URL
} from '../../STRINGS/Strings';
import { GenreType } from '../../FRONTEND/INTERFACE AND PROPS/Interface';
import { returnTopArtistsAsProps, returnTopTracksAsProps } from '../SpotifyHelperFunctions';

// AXIOS DEFAULTS
axios.defaults.baseURL = 'https://api.spotify.com/v1';
axios.defaults.headers['Content-Type'] = 'application/json';
const accessToken = localStorage.getItem(RP_LOCAL_STORAGE_ACCESS_TOKEN)
axios.defaults.headers['Authorization'] = `Bearer ${accessToken}`;

export const getCurrentUserProfile = async () => {
    const res = await axios.get("/me")
    return res
}

export const getTopArtists = async (range: string ) => {
    const { data } = await axios.get(`${RP_TOP_ARTISTS_URL}?time_range=${range}&limit=5`)
    const props = returnTopArtistsAsProps(data.items)
    return props
}

export const getTopTracks = async (range: string ) => {
    const { data } = await axios.get(`${RP_TOP_TRACKS_URL}?time_range=${range}&limit=5`)
    const props = returnTopTracksAsProps(data.items)
    return props
}

export const getTopGenre = async (range: string) => {
    const { data } = await axios.get(`${RP_TOP_TRACKS_URL}?time_range=${range}&limit=50`)
    const genreMapping: any = {}
    for (let item of data.items) {
        const track = await axios.get(item.href)
        const artist = await axios.get(track.data.artists[0].href)
        for (let genre of artist.data.genres) {
            if (genre in genreMapping) {
                genreMapping[genre] += 1
            } else {
                genreMapping[genre] = 1       
            }
        }
    }

    const genres = Object.entries(genreMapping)
    genres.sort((a, b) => {
        return (b[1] as number) - (a[1] as number)
    })

    const topGenre = genres[0][0].toUpperCase() as GenreType
    const topGenres = genres.slice(0, 5).map(g => {
        return g[0]
    })

    return {
        RP_GENRES: topGenres,
        RP_TOP_GENRE: topGenre
    }
}

export const getValidGenres = async () => {
    const {data} = await axios.get(`https://api.spotify.com/v1/recommendations/available-genre-seeds`)
    return data.genres
}

export const getTopGenres = async (genres: string[]) => {
    let validGenres = await getValidGenres()

    const seedGenres = genres.filter((genre: string) => {
        return validGenres.includes(genre)
    })
    return seedGenres
}

export const getRecommendations = async (artists: string, tracks: string, genres:string) => {
    
    const options = {
        method: 'get',
        url: `${RP_RECOMMENDATIONS_URL}/?seed_tracks=${tracks}&seed_genres=${genres}&seed_artists=${artists}`,
    }
    const {data} = await axios(options)

    return data.tracks
}
