import React, { useEffect, useRef, useState } from "react";
import { TrackProp } from "../INTERFACE AND PROPS/RoundupProps";
import RoundedIcon from "./RoundedIcon";

const Track = ({ track }: TrackProp) => {
    // VARIABLES
    const [isPlaying, setIsPlaying] = useState(false)
    let audio = new Audio()
    const audioRef = useRef(audio)

    // HOOKS
    useEffect(() => {
        if (track.preview) {
            const xhr = new XMLHttpRequest()
            xhr.open("GET", track.preview, true)
            xhr.responseType = "blob"
            xhr.onload = () => {
                audioRef.current.src = URL.createObjectURL(xhr.response)
            }
            xhr.send()
        }
    }, [track.preview])

    // FUNCTIONS
    const isHover = (isHover: boolean) => {
        if (isHover) {
            if (!isPlaying) {
                audioRef.current.play().then(response => {
                    setIsPlaying(true)
                }).catch(error => {
                    console.error(error.message)
                })
            }
        } else {
            setIsPlaying(false)
            audioRef.current.pause()
        }
    }

    return (
        <div className="rp-track is-flex is-justify-items-center is-align-items-center">

            <RoundedIcon image={track.album.cover} shrink={true} isHover={isHover} />

            <button id="hidden-button"
                style={{
                    "display": "none"
                }}
            ></button>

            <div className="rp-track-info is-flex is-flex-direction-column is-justify-content-flex-start is-align-items-flex-start">
                <h2 className="rp-light-small">{track.title}</h2>
                <h2 className="rp-heavy-small">{track.artist}</h2>

            </div>

        </div>

    )

}


export default Track