import React, { useEffect, useState } from "react";
import { useDispatch, useSelector } from "react-redux";
import { RecommendationProp } from "../INTERFACE AND PROPS/RoundupProps";
import { Track } from "../INTERFACE AND PROPS/Interface";
import { nextRecommendation, resetRecommendations } from "../REDUX AND STATE/RecommendationActions";
import { getRecommendationAsTrackProp } from "../../BACKEND/SpotifyHelperFunctions";
import Recommendation from "../Components/Recommendation";

const Recommendations = ({ recommendations, isHovering, updateCursor }: RecommendationProp) => {
    // VARIABLES
    const recCounter = useSelector((state: any) => state.recCounter.value)
    const [recommendation, setRecommendation] = useState<Track>()
    const dispatch = useDispatch()
    
    if (recCounter === recommendations.length) {
        resetRecommendations(dispatch)
    }
    // HOOKS
    useEffect(() => {
        if (recommendations.length > 0 && recCounter === recommendations.length) {
            resetRecommendations(dispatch)
        }

        if (recommendations.length) {
            console.log(recommendations)

            setRecommendation(getRecommendationAsTrackProp(recommendations[recCounter]))
            nextRecommendation(dispatch)
        }

    }, [recommendations])

    // FUNCTIONS  
    const toggleImage = () => {
        if (recCounter === recommendations.length) {
            resetRecommendations(dispatch)
        }

        setRecommendation(getRecommendationAsTrackProp(recommendations[recCounter]))
        nextRecommendation(dispatch)

    }

    // CONDITIONSS
    

    return (
        <div className="rp-total-recommendations rp-section rp-three-fifths-height is-flex is-align-items-center is-justify-content-space-between ">
            <div className="rp-recommendations is-flex is-flex-direction-column is-align-content-flex-start">
                <h3 className="rp-heavy-medium">i think<br></br>you<br></br>might<br></br>like<br></br>these<br></br>ones<br></br> too</h3>
                <h4 className="rp-subscript">*maybe</h4>
            </div>
            {
                recommendation
                    ?
                    <Recommendation track={recommendation} isHover={isHovering} generateRecommendation={toggleImage} />
                    :
                    <div className="rp-loading-dots-outer is-flex is-align-items-center is-justify-content-center">
                        <div className="rp-loading-dots is-flex is-align-items-center">
                            <div></div>
                            <div></div>
                            <div></div>
                            <div></div>
                        </div>

                    </div>
            }
        </div>
    )
}

export default Recommendations