import React from "react";
import { ArtistActionProp } from "../INTERFACE AND PROPS/RoundupProps";
import RoundIconOverlay from "./RoundIconOverlay";

const Artists = ({ artist, isHover, hide }: ArtistActionProp) => {

    // FUNCTIONS
    const isHovering = (isHovering: boolean) => {
        if (isHovering) {
            isHover(isHovering, artist)
        } else {
            isHover(isHovering, null)
        }
    }

    return (
        <div className="rp-artist"
            style={{
                "visibility": `${hide ? "visible" : "hidden"}`
            } as React.CSSProperties}
        >
            <RoundIconOverlay name={artist.name} image={artist.images[0]} isHover={isHovering} />
        </div>

    )

}

export default Artists