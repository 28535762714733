import React from "react";
import { ImageActionProp } from "../INTERFACE AND PROPS/RoundupProps";

const RoundedIcon = ({ image, shrink, isHover }: ImageActionProp) => {

    return (
        <div className="rp-rounded-icon"
            onMouseEnter={() => isHover(true)}
            onMouseLeave={() => isHover(false)}

        >
            <img src={image === undefined ? "" : image} alt="" className={`rp-rounded-icon-img${shrink ? "-shrink" : ""}`}

            />
            {
                shrink ? <div className="rp-background"

                ></div> : null
            }
        </div>
    );


}

export default RoundedIcon