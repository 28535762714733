import React from "react";
import { ImageProp } from "../INTERFACE AND PROPS/RoundupProps";

const RoundedIconPlain = ({ image }: ImageProp) => {

    return (
        <div className="rp-rounded-icon-plain">
            <img src={`${image === undefined ? "" : image}`} alt="" className="rp-round-icon-img" />
        </div>
    );

}

export default RoundedIconPlain