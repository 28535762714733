import axios from 'axios'
import {
    API_BASE,
    RP_LOCAL_STORAGE_ACCESS_TOKEN,
    RP_LOCAL_STORAGE_EXPIRE_TIME,
    RP_LOCAL_STORAGE_REFRESH_TOKEN,
    RP_LOCAL_STORAGE_TIME
} from '../../STRINGS/Strings'
const api = axios.create()


export const getCredentials = async () => {
    const res = await api.get(`${API_BASE}/login`)
    return res
}

export const getAccessInformationFromLocalStorage = () => {

    return {
        RP_LOCAL_STORAGE_ACCESS_TOKEN: localStorage.getItem(RP_LOCAL_STORAGE_ACCESS_TOKEN),
        RP_LOCAL_STORAGE_REFRESH_TOKEN: localStorage.getItem(RP_LOCAL_STORAGE_REFRESH_TOKEN),
        RP_LOCAL_STORAGE_EXPIRE_TIME: localStorage.getItem(RP_LOCAL_STORAGE_EXPIRE_TIME),
        RP_LOCAL_STORAGE_TIME: localStorage.getItem(RP_LOCAL_STORAGE_TIME)
    }
}

export const setAccessInformationToLocalStorage = (accessToken: string, refreshToken: string, expiresIn: string, time: string) => {

    localStorage.setItem(RP_LOCAL_STORAGE_ACCESS_TOKEN, accessToken)
    localStorage.setItem(RP_LOCAL_STORAGE_REFRESH_TOKEN, refreshToken)
    localStorage.setItem(RP_LOCAL_STORAGE_EXPIRE_TIME, expiresIn)
    localStorage.setItem(RP_LOCAL_STORAGE_TIME, time)

}

export const getAccessInformationFromSearchParams = (params: URLSearchParams) => {
    // Check if we already have values saved in localstorage
    const storedValues = getAccessInformationFromLocalStorage()

    if (storedValues && storedValues.RP_LOCAL_STORAGE_ACCESS_TOKEN && storedValues.RP_LOCAL_STORAGE_ACCESS_TOKEN !== 'null') {
        // If we have a valid access token saved, check if it's expired
        const expiryTime = storedValues.RP_LOCAL_STORAGE_EXPIRE_TIME
        const storedTime = storedValues.RP_LOCAL_STORAGE_TIME
        const hasExpired = checkIfAccessTokenExpired(Number(expiryTime), Number(storedTime))

        // If it's expired, refresh it
        if (hasExpired) {
            // Check if refreshToken exists and is valid
            if (storedValues.RP_LOCAL_STORAGE_REFRESH_TOKEN && storedValues.RP_LOCAL_STORAGE_REFRESH_TOKEN !== 'null') {
                refreshAccessToken(storedValues.RP_LOCAL_STORAGE_REFRESH_TOKEN).then(response => {
                    const { access_token } = response.data

                    // update the access token and update the time stored
                    updateAccessTokensInLocalStorage(access_token)
                    //clearSeedStorage()
                    

                }).catch(error => {
                    return {
                        RP_ERROR: error,
                        RP_LOCAL_STORAGE_ACCESS_TOKEN: null
                    }
                })
            } else {
                return {
                    RP_ERROR: "No valid refresh token",
                    RP_LOCAL_STORAGE_ACCESS_TOKEN: null
                }
            }
        
        // If it's not expired, return the stored values
        } else {
            return storedValues
        }
        
    } else {
        // If we don't have at least an access token stored, store everything
        const accessToken = params.get("access_token") as string
        const refreshToken = params.get("refresh_token") as string
        const expiresIn = params.get("expires_in") as string

        setAccessInformationToLocalStorage(accessToken, refreshToken, expiresIn, Date.now().toString())
    }

    // Either way, we call our return function to return newly saved values
    return storedValues
}

export const checkIfAccessTokenExpired = (expiryTime: number, storedTime: number) => {
    const timePassed = Date.now() - storedTime
    return (timePassed / 1000) > expiryTime
}

export const refreshAccessToken = async (refreshToken: string) => {
    const res = await axios.get(`${API_BASE}/refresh/${refreshToken}`)
    return res 
}

export const updateAccessTokensInLocalStorage = (accessToken: string) => {
    localStorage.setItem(RP_LOCAL_STORAGE_ACCESS_TOKEN, accessToken)
    localStorage.setItem(RP_LOCAL_STORAGE_TIME, Date.now().toString())
    window.location.reload()
}