import { RP_NEXT, RP_PREV, RP_RESET } from "../../STRINGS/Strings"

export const nextRecommendation = async (dispatch: any) => {
    
    dispatch({
        type: RP_NEXT
    })
}

export const prevRecommendation = async (dispatch: any) => {
    
    dispatch({
        type: RP_PREV
    })
}

export const resetRecommendations = async (dispatch: any) => {
    
    dispatch({
        type: RP_RESET
    })
}