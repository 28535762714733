import {legacy_createStore as createStore, applyMiddleware, Store, combineReducers } from "@reduxjs/toolkit";
import thunk from "redux-thunk";
import { RP_ADD_PLAYER, RP_DISCONNECT_PLAYER, RP_NEXT, RP_PREV, RP_RESET } from "../../STRINGS/Strings";
import { RecommendationCounterState, SpotifyPlayerState } from "../INTERFACE AND PROPS/Interface";

const initialCounterState: RecommendationCounterState = {
    value: 0
}

const RecommendationReducer = (state: RecommendationCounterState = initialCounterState, action: any): RecommendationCounterState => {
    switch (action.type) {
        case RP_NEXT:
            return {
                value: state.value + 1
            }
        case RP_PREV:
            return {
                value: state.value - 1
            }
        case RP_RESET:
            return {
                value: 0
            }
        default:
            return state
    }
}

const initialPlayerState: SpotifyPlayerState = {
    player: null
}
const SpotifyPlayerReducer = (state: SpotifyPlayerState = initialPlayerState, action: any): SpotifyPlayerState => {
    switch (action.type) {
        case RP_ADD_PLAYER:
            return {
                player: action.player
            }
        case RP_DISCONNECT_PLAYER:
            state.player?.disconnect()
            return state
        default:
            return state
    }

}

const reducers = combineReducers({
    recCounter: RecommendationReducer,
    player: SpotifyPlayerReducer
})

export const store:Store<any, any> & {
  dispatch: any
} = createStore(reducers, applyMiddleware(thunk))

export type RootState = ReturnType<typeof store.getState>
export type AppDispatch = ReturnType<typeof store.dispatch>
