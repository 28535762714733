export const API_BASE = process.env.REACT_APP_RP_BASE

// LOCAL STORAGE
export const RP_LOCAL_STORAGE_ACCESS_TOKEN = "access_token_ls"
export const RP_LOCAL_STORAGE_REFRESH_TOKEN = "refresh_token_ls"
export const RP_LOCAL_STORAGE_EXPIRE_TIME = "expires_in_ls"
export const RP_LOCAL_STORAGE_TIME = "time_first_set_ls"
export const RP_ERROR = "error"

export const RP_NAME = "roundup"
export const RP_EMAIL = "hello@myroundup.me"
export const RP_URL= "https://www.myroundup.me"

export const RP_GENRES = "genres"
export const RP_TOP_GENRE = "genre"
export const RP_TOP_ARTISTS = "artists"
export const RP_TOP_TRACKS = "tracks"

export const RP_ARTIST_SEED = "artists_seed"
export const RP_TRACK_SEED = "tracks_seed"
export const RP_GENRE_SEED = "genres_seed"
export const RP_RANGE_SEED = "range_seed"

export const RP_LOCAL_STORAGE_KEYS = [
    RP_LOCAL_STORAGE_ACCESS_TOKEN,
    RP_LOCAL_STORAGE_EXPIRE_TIME,
    RP_LOCAL_STORAGE_REFRESH_TOKEN,
    RP_LOCAL_STORAGE_TIME,
    RP_TRACK_SEED,
    RP_ARTIST_SEED,
    RP_GENRE_SEED,
    RP_RANGE_SEED
]

// URLS
export const RP_TOP_ARTISTS_URL = "/me/top/artists"
export const RP_TOP_TRACKS_URL = "/me/top/tracks"
export const RP_RECOMMENDATIONS_URL = "https://api.spotify.com/v1/recommendations"
export const WEB_PLAYBACK_URL = "https://sdk.scdn.co/spotify-player.js"

// PLAYBACK
export const RP_LOADER_SVG = "loadingBars.svg"
export const RP_PLAYER_READY = "ready"
export const RP_PLAYER_READY_MSG = "Ready with Device ID"
export const RP_PLAYER_NOT_READY = "not_ready"
export const RP_PLAYER_NOT_READY_MSG = "Device ID has gone offline"
export const RP_PLAYER_STATE_CHANGED = "player_state_changed"
export const RP_PLAYER_AUTOPLAY_FAILED = "autoplay_failed"
export const RP_PLAYER_AUTOPLAY_FAILED_MSG = "'Autoplay is not allowed by the browser autoplay rules'"
export const RP_PLAYER_NAME = "ROUNDUP PLAYER"
export const RP_LOCAL_STORAGE_DEVICE = "device_id"
export const RP_PLAY_BUTTON_NAME = "rp-play-pause"
export const RP_ROOT_ELEMENT = "rp-root-id"

export const RP_SCRIPT_STATE = "state"
export const RP_SPOTIFY_PLAYER = "spotify_player"
export const RP_IS_PLAYING = "is_playing"
export const RP_IS_PAUSED = "is_paused"

export const IDLE = "idle"
export const LOADING = "load"
export const ERROR = "error"
export const READY = "ready"

// ROUTES

export const RP_HOME_ROUTE = "home"
export const RP_THIS_MONTH_ROUTE = "month"
export const RP_SIX_MONTHS_ROUTE = "six-months"
export const RP_ALL_TIME_ROUTE = "all"
export const RP_WELCOME_ROUTE = "welcome"
export const RP_ERROR_ROUTE = "error"
export const RP_PRIVACY_POLICY_ROUTE = "privacy_policy"
export const RP_CONTACT_ROUTE= "contact"
export const RP_SUCCESS_PAGE = "success"
export const RP_SUCCESS_PAGE_TITLE = "Awesome!"
export const RP_SUCCESS_PAGE_MESSAGE = "Your form was succesfully submitted."
export const RP_FAILURE_PAGE = "failure"
export const RP_FAILURE_PAGE_TITLE = "Oh no! Something went wrong."
export const RP_FAILURE_PAGE_MESSAGE = "Try again?"
export const RP_404_PAGE = "404"
export const RP_404_PAGE_MESSAGE = "Oops! This page doesn't exist."
export const RP_LOGIN_ROUTE = "login"
export const RP_EMAIL_LINK = "mailto:hello@roundup.me?subject=Interesting in Connecting"


// RANGE
export const RP_SHORT_TERM_RANGE = "short_term"
export const RP_LONG_TERM_RANGE = "long_term"
export const RP_MEDIUM_TERM_RANGE = "medium_term"


// ERRORS

/* PLAYBACK ERRORS */
export const RP_INIT_ERROR = "initialization_error"
export const RP_AUTH_ERROR = "authentication_error"
export const RP_ACCOUNT_ERROR = "account_error"
export const RP_PLAYBACK_ERROR = "playback_error"
export const RP_FAILED_TO_PERFORM_PLAYBACK = "playback_error"

/* SET UP ERRORS */
export const RP_SET_ERROR = "account_set_up_error"
export const RP_DATA_RETRIEVAL_ERROR = "data_retrieval_error"
export const RP_SEED_GENERATION_ERROR = "seed_generation_error"

// DISPATCH ACTIONS

// COUNTER
export const RP_NEXT = "NEXT"
export const RP_PREV = "PREV"
export const RP_RESET = "RESET"
// PLAYER
export const RP_ADD_PLAYER = "ADD_PLAYER"
export const RP_DISCONNECT_PLAYER = "DISCONNECT_PLAYER"

// OTHER

export const RP_TRACK_VALUE = "rp_track_value"
export const RP_ARTIST_VALUE = "rp_track_value"

// COLORS
export const RP_RED = "#f93943"
