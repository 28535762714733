import React, { useEffect, useRef } from "react"
import "./Style/css/style.css"
import {
  RP_EMAIL,
  RP_EMAIL_LINK,
  RP_FAILURE_PAGE,
  RP_SUCCESS_PAGE,
} from "../STRINGS/Strings"
import { clearStorage } from "../BACKEND/SpotifyHelperFunctions"
import { useNavigate } from "react-router-dom"

function Contact() {
  // VARIABLES
  const nameRef = useRef<HTMLInputElement>(null)
  const emailRef = useRef<HTMLInputElement>(null)
  const messageRef = useRef<HTMLTextAreaElement>(null)
  const navigate = useNavigate()

  // HOOKS
  useEffect(() => {
    clearStorage()
  })

  const encode = (data: any) => {
    return Object.keys(data)
      .map(
        (key) => encodeURIComponent(key) + "=" + encodeURIComponent(data[key])
      )
      .join("&")
  }
  const handleSubmit = (
    event: React.FormEvent<HTMLFormElement> | React.FormEvent<HTMLInputElement>
  ) => {
    event.preventDefault()

    const options = {
      method: "POST",
      headers: {
        "Content-Type": "application/x-www-form-urlencoded",
      },
      body: encode({
        "form-name": "roundup-contact-form",
        "FORM_NAME": nameRef.current?.value,
        "FORM_EMAIL": emailRef.current?.value,
        "FORM_MESSAGE": messageRef.current?.value,
      }),
    }

    fetch("/", options).then((res) => {
        navigate(`/${RP_SUCCESS_PAGE}`)
      })
      .catch((error) => {
        console.error(error)
        navigate(`/${RP_FAILURE_PAGE}`)
      })
  }

  return (
    <div className="rp-content is-flex rp-contact is-flex-direction-column is-align-items-center">
      <div className="rp-welcome-text">
        <h3 className="rp-heavy-large" style={{ textAlign: "center" }}>
          Get in touch!
        </h3>
      </div>
      <h4 className="rp-paragraph-text">
        Send a quick message below or reach out at{" "}
        <form action={RP_EMAIL_LINK} method="GET" encType="text/plain">
          <label
            htmlFor="rp_submit_email"
            className="rp-plain is-underlined rp-bold">
            {RP_EMAIL}
          </label>
          <input
            type="submit"
            id="rp_submit_email"
            value={" "}
            className="is-hidden"
          />
        </form>
      </h4>
      <form
        className="is-flex is-flex-direction-column is-justify-content-center rp-paragraph-text rp-left mt-6"
        id="rp-contact-form"
        name="roundup-contact-form"
        action={`/${RP_SUCCESS_PAGE}`}
        onSubmit={(e) => handleSubmit(e)}>
        <input type="hidden" name="form-name" value="roundup-contact-form" />
        <div className="is-flex is-flex-direction-column mt-4">
          <label htmlFor="rp-form-name" className="rp-label is-uppercase">
            Name
          </label>
          <input
            type="text"
            id="rp-form-name"
            className="rp-input rp-paragraph-text rp-left"
            name="rp-contact-name"
            ref={nameRef}
            required
          />
        </div>

        <div className="is-flex is-flex-direction-column mt-4">
          <label htmlFor="rp-form-email" className="rp-label is-uppercase">
            Email
          </label>
          <input
            type="email"
            id="rp-form-email"
            className="rp-input rp-paragraph-text rp-left"
            name="rp-contact-email"
            ref={emailRef}
            required
          />
        </div>

        <div className="is-flex is-flex-direction-column mt-4">
          <label htmlFor="rp-form-msg" className="rp-label is-uppercase">
            Message
          </label>
          <textarea
            id="rp-form-msg"
            className="rp-textarea rp-paragraph-text rp-left"
            rows={5}
            cols={30}
            name="rp-contact-message"
            ref={messageRef}
            required
          />
        </div>

        <input
          type="submit"
          form="rp-contact-form"
          className="is-uppercase rp-submit rp-bold mt-4"
          onSubmit={(e) => handleSubmit(e)}
        />
      </form>
    </div>
  )
}

export default Contact
